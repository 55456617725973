






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import adsAgencyBalanceChangeTypes from "@/constants/adsAgencyBalanceChangeTypes";
import makeSelectOptions from "@common/util/makeSelectOptions";

export default Vue.extend({
  data() {
    return {
      self: this,
      tableOptions: null,
      item: null,
    };
  },
  methods: {
    makeTableOptions(item) {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          name: "Quản lý biến động số dư",
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              description: {
                attrs: {
                  label: "Mô tả",
                },
                rules: {
                  match: {},
                },
              },
              type: {
                type: "select",
                attrs: {
                  label: "Loại biến động",
                  items: makeSelectOptions(adsAgencyBalanceChangeTypes),
                },
                rules: {
                  equal_to: {},
                },
              },
              time: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Thời gian (bắt đầu)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Thời gian (kết thúc)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              value: {
                type: "integer",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Số tiền (>=)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Số tiền (<=)",
                    },
                  },
                },
              },
              adsAgencyId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Agencies",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("adsAgencies", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
            },
          },
          displayFields: {
            type: {
              text: "Loại biến động",
              sortable: true,
              options: {
                label(value) {
                  return adsAgencyBalanceChangeTypes[value || ""];
                },
              },
            },
            time: {
              text: "Thời gian",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            adsCard: item.type === "tkcn" && {
              text: "Thẻ",
              sortable: true,
              options: {
                label: true,
                subProp: "adsCard.name",
                sortBy: "adsCardId",
              },
            },
            value: {
              text: "Số tiền",
              sortable: true,
              options: {
                filter: "number3",
              },
            },
            changedValue: {
              text: "Giá trị biến động",
              sortable: true,
              options: {
                filter: "number3",
              },
            },
            description: {
              text: "Mô tả",
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          topActionButtons: {
            insert: userManager.checkRole(["kt", "ksnb", "ksnbl"]) && {},
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt", "ksnb", "ksnbl"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "description",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "adsAgencyId",
              operator: "equal_to",
              value: this.context().$route.params.adsAgencyId,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("adsAgencyBalanceChanges", "findAll", options);
              return [items, count];
            },
            async insert(item, dataTable) {
              item.adsAgencyId = dataTable.context().$route.params.adsAgencyId;
              return await coreApiClient.call("adsAgencyBalanceChanges", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "adsAgencyBalanceChanges",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("adsAgencyBalanceChanges", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              type: {
                type: "select",
                attrs: {
                  label: "Phân loại",
                  required: true,
                  items: makeSelectOptions(adsAgencyBalanceChangeTypes),
                },
              },
              time: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Thời gian",
                },
              },
              value: {
                type: "integer",
                attrs: {
                  label: "Số tiền",
                },
              },
              adsCardId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Thẻ",
                  "item-value": "_id",
                  "item-text": "name",
                  required: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("adsCards", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                ext: {
                  condition() {
                    return item.type === "tkcn";
                  },
                },
              },
            },
            insertForm: {
              content: {
                fieldNames: ["description", "adsCardId", "type", "time", "value"],
              },
            },
            editForm: {
              content: {
                fieldNames: ["description", "adsCardId", "type", "time", "value"],
              },
            },
            delete: {
              confirmDisplayField: "description",
            },
          },
        },
      };
    },
  },
  async created() {
    this.item = await coreApiClient.call("adsAgencies", "findById", {
      id: this.$route.params.adsAgencyId,
    });
    this.tableOptions = this.makeTableOptions(this.item);
  },
});
